<div class="logo">
	<a href="javascript:void(0)"
		class="simple-text logo-mini">
		<div class="logo-img"></div>
	</a>
	<a href="javascript:void(0)"
		class="simple-text logo-normal">
		YASHASWI
	</a>
</div>
<div class="sidebar-wrapper">
	<ul class="nav">
		<li *ngFor="let menuItem of menuItems"
			routerLinkActive="active"
			class="{{ menuItem.class }} nav-item">
			<a *ngIf="menuItem.isRole"
				[routerLink]="[menuItem.path]">
				<i class="tim-icons  {{ menuItem.icon }}"></i>
				<p class="text-capitalize">
					{{ menuItem.title }}
				</p>
			</a>
		</li>
	</ul>
</div>
