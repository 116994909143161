import { Component } from "@angular/core";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {

  title = "HU FOTA";
  constructor(private idle: Idle) {
  idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

  idle.onIdleEnd.subscribe(() => {
  });
}
}
