import { Component, OnInit } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Role } from "src/app/model/role";

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  isRole: boolean;
}
export const ROUTES: RouteInfo[] = [
  
  {
    path: "/shortpaysliplist",
    title: "Pay Slip List",
    icon: "icon-laptop",
    class: "",
    isRole: false
  },
  {
    path: "/uploaddata",
    title: "Upload Pay Slip",
    icon: "icon-laptop",
    class: "",
    isRole: false
  },
  {
    path: "/payslipreport",
    title: "Pay Slip Report",
    icon: "icon-laptop",
    class: "",
    isRole: false
  }
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  jwtHelper = new JwtHelperService();
  rolelist: any;
  rolelist1: string = "";

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    const lsRole = window.localStorage.getItem("Role");
    if(lsRole=='Student'){
      this.menuItems[0].isRole = true;

    }
    else{
      this.menuItems[1].isRole = true;
      this.menuItems[2].isRole = true;
    }
  
   
  }
  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }
}
