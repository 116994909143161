import { Component, OnInit } from "@angular/core";
import { NgxSpinnerModule } from "ngx-spinner";
@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"]
})
export class AdminLayoutComponent implements OnInit {
  public sidebarColor: string = "red";

  constructor() {   var body = document.getElementsByTagName('body')[0];
  body.classList.add("white-content")
}
  ngOnInit() {

    var sidebar = document.getElementsByClassName('sidebar')[0];
    var mainPanel = document.getElementsByClassName('main-panel')[0];

    this.sidebarColor = "primary";

    if(sidebar != undefined){
        sidebar.setAttribute('data',"primary");
    }
    if(mainPanel != undefined){
        mainPanel.setAttribute('data',"primary");
    }
  }
}
