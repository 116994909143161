import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppRoutingModule } from "./app-routing.module";
import { ComponentsModule } from "./components/components.module";
import { NgImageSliderModule } from 'ng-image-slider';
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { NgxPaginationModule } from 'ngx-pagination';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TooltipModule } from 'ng2-tooltip-directive';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ComponentsModule,
    NgbModule,
    NgImageSliderModule,
    RouterModule,
    ToastrModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    SlickCarouselModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
    TooltipModule,


  ],
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
