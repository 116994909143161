<footer class=" footer">
	<div class=" container-fluid">
		<ul class=" nav">
			<li class=" nav-item">
				<a class=" nav-link"
					href="javascript:void(0);">
					<!-- Creative Tim -->
				</a>
			</li>
			<li class=" nav-item">
				<a class=" nav-link"
					href="javascript:void(0);">
					<!-- About Us -->
				</a>
			</li>
			<li class=" nav-item">
				<a class=" nav-link"
					href="javascript:void(0);">
					<!-- Blog -->
				</a>
			</li>
		</ul>
		<div class=" copyright">
			<!-- &copy; {{ test | date: "yyyy" }} made with
			<i class=" tim-icons icon-heart-2"></i> -->
			<!-- by Creative Tim -->
      copyright details.
			<!-- <a href="https://www.creative-tim.com?ref=bda-footer" target="_blank"> Creative Tim </a>
      for a better web. -->
		</div>
	</div>
</footer>
