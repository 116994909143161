<nav class=" navbar navbar-expand-lg navbar-absolute navbar-transparent">
	<div class=" container-fluid">
		<div class=" navbar-wrapper">
			<div class=" navbar-toggle d-inline">
				<button (click)="sidebarToggle()"
					class=" navbar-toggler"
					type="button">
					<span class=" navbar-toggler-bar bar1"></span>
					<span class=" navbar-toggler-bar bar2"></span>
					<span class=" navbar-toggler-bar bar3"></span>
				</button>
			</div>
			<a class="text-capitalize navbar-brand"
				href="javascript:void(0)">
				{{ getTitle() }}
			</a>
		</div>
		<button [attr.aria-expanded]="!isCollapsed"
			(click)="collapse()"
			aria-label="Toggle navigation"
			class=" navbar-toggler"
			aria-controls="collapseExample"
			id="navigation"
			type="button">
			<span class=" navbar-toggler-bar navbar-kebab"></span>
			<span class=" navbar-toggler-bar navbar-kebab"></span>
			<span class=" navbar-toggler-bar navbar-kebab"></span>
		</button>
		<div [ngbCollapse]="isCollapsed"
			class=" navbar-collapse"
			id="navigation">
			<ul class=" navbar-nav ml-auto">
				<li class=" nav-item"
					ngbDropdown>
					<a class=" nav-link"
						data-toggle="dropdown"
						href="javascript:void(0)"
						ngbDropdownToggle>
						<div class=" photo">
							<img alt="Profile Photo"
								src="assets/img/anime3.png">
						</div>
						<b class=" caret d-none d-lg-block d-xl-block"></b>
						<span class=" d-lg-none">Log out</span>
					</a>
					<ul class=" dropdown-navbar"
						ngbDropdownMenu>
						<li class=" nav-link">
							<a class=" nav-item"
								href="javascript:void(0)"
								ngbDropdownItem>
								<h5>
									{{ currentUser.Displayname | titlecase }}
								</h5>
								<p>
									{{ currentUser.LoginIDEmail }}
								</p>
							</a>
						</li>
						
						<li class=" dropdown-divider"></li>
						<li class=" nav-link">
							<a (click)="logout()"
								class=" nav-item"
								href="javascript:void(0)"
								ngbDropdownItem>
								Log out
							</a>
						</li>
					</ul>
				</li>
				<li class=" separator d-lg-none"></li>
			</ul>
		</div>
	</div>
</nav>
<ng-template #content
	let-modal>
	<div class=" modal-header">
		<input class=" form-control"
			id="inlineFormInputGroup"
			placeholder="SEARCH"
			type="text">
		<button (click)="modal.dismiss('Cross click')"
			aria-label="Close"
			class=" close"
			data-dismiss="modal"
			type="button">
			<i class=" tim-icons icon-simple-remove"></i>
		</button>
	</div>
</ng-template>
